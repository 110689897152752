import { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import { Text } from '@chakra-ui/react';
import {
  APPLICATION_URL,
  LOCAL_STORAGE_ENCRYPTION_KEY,
  FEATURE_FLAG_CATALOG_ENHANCEMENTS,
  PDP_CACHE_MAX_AGE,
  PDP_CACHE_STALE,
} from 'composable/components/general';
import { isPublicPage } from 'composable/helpers/utils/is-public-page';
import { getServerAzureLogoutUrl, getServerShamrockRedirectUrl } from 'composable/helpers/utils/use-user-utils';
import routes from 'helpers/constants/routes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createClient, ResponseError, LocaleStorage, useDarkMode, PageDataResponse } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  slug: string;
};

export default function Slug({ data, locale, slug }: SlugProps) {
  const { applyTheme } = useDarkMode();

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pageFolder?.configuration]);

  LocaleStorage.locale = locale;

  if (!data || typeof data === 'string') {
    return (
      <>
        <Text as="h1" mt={2} fontSize="4xl" fontWeight="extrabold" color="gray.900">
          Internal Error
        </Text>
        <Text mt={2} fontSize="lg">
          {data}
        </Text>
        <Text mt={2} fontSize="lg">
          Check the logs of your Frontastic CLI for more details.
        </Text>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <Text as="h1" mt={2} fontSize="4xl" fontWeight="extrabold" color="gray.900">
          Internal Error
        </Text>
        <Text mt={2} fontSize="lg">
          {data!.message}
        </Text>
        <Text mt={2} fontSize="lg">
          Check the logs of your Frontastic CLI for more details.
        </Text>
      </>
    );
  }

  return <FrontasticRenderer data={data} slug={slug} tastics={tastics} wrapperClassName={styles.gridWrapper} />;
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;
  const frontastic = createClient();

  if (query.errorAccessToken == 'true') {
    //let's redirect the customer to shamrock login page, so we get the correct accessToken
    const destination =
      query.shouldLogoutAzure === 'true'
        ? getServerAzureLogoutUrl(query.errorCode as string)
        : getServerShamrockRedirectUrl(APPLICATION_URL, query.errorCode as string);
    return {
      redirect: {
        destination,
        statusCode: 301,
      } as Redirect,
    };
  }

  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if ((query?.path as string).match(/(?<=\/category\/)(.*)/) && !FEATURE_FLAG_CATALOG_ENHANCEMENTS) {
    return {
      redirect: {
        destination: routes.HOME,
        statusCode: 301,
      } as Redirect,
    };
  }

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      const slug = params?.slug;
      const destination = slug?.includes('public') ? routes.PUBLIC_NOT_FOUND : routes.NOT_FOUND;
      return {
        redirect: {
          destination,
          statusCode: 301,
        } as Redirect,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  if (isPublicPage(data as PageDataResponse)) {
    res.setHeader('Cache-Control', `public, s-maxage=${PDP_CACHE_MAX_AGE}, stale-while-revalidate=${PDP_CACHE_STALE}`);
  }

  const encryptionKey = { encryptionKey: LOCAL_STORAGE_ENCRYPTION_KEY || 'sBv1Q2W3e4R5t6Y7u8I9o0P1q2W3e4R5' };

  return {
    props: {
      data: data || null,
      locale: locale,
      slug: query.path,
      ...(await serverSideTranslations(locale, ['common'])),
      ...encryptionKey,
    },
  };
};
